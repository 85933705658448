<section
  id="credentials-entry"
  class="member-credentials"
  [ngClass]="{
    entry: !(isSecure$ | async),
    placeholder: isSecure$ | async
  }"
>
  <form
    id="signon-form"
    (ngSubmit)="formState.isValid && handleSubmit()"
    class="member-credentials_form"
    [ngClass]="{'error': isErrorBoxDisplayed }"
    novalidate
    [ngrxFormState]="formState"
  >
    <ng-container *ngIf="!(isSecure$ | async)">
      <ng-container *ngIf="allowNameAuth; else membershipLines">
        <app-login-lite [controls]="controls"></app-login-lite>
      </ng-container>
      <ng-template #membershipLines>
        <app-member-number [controls]="controls"></app-member-number>
      </ng-template>
      <div>
        <ng-container *ngIf="isPasswordCredentialSupported">
          <drr-checkbox
            i18n
            name="remember-me"
            (change)="handleRememberMe($event)"
            [checked]="rememberMe"
            justify="start"
            labelPlacement="end"
          >
            Remember me
          </drr-checkbox>
        </ng-container>
        <app-login-fail-assistant (hasError)="setHasError($event)"></app-login-fail-assistant>
        <ng-container *ngIf="!(captchaModalOpen$ | async) && !hideCatchaInPage">
          <ng-container *ngTemplateOutlet="captchaV2; context: { $implicit: { triggerAuth: false } }"></ng-container>
        </ng-container>
        <app-form-helper
          i18n
          *ngIf="captchaError"
        >
          You must confirm you are not a robot.
        </app-form-helper>
        <aaa-button
          i18n
          id="btn-confirm-default"
          type="submit"
          [loading]="(isSigningIn$ | async) === true"
          expand="block"
          variant="primary"
        >
          Confirm
        </aaa-button>
        <ng-container *ngIf="allowNameAuth; else btnUseMemberName">
          <aaa-button
            i18n
            id="use-member-number"
            expand="block"
            variant="ghost"
            (click)="useMembershipNumberClicked($event)"
          >
            Use Member Number To Sign In
          </aaa-button>
        </ng-container>
        <ng-template #btnUseMemberName>
          <aaa-button
            i18n
            id="use-member-name"
            expand="block"
            variant="ghost"
            (click)="useMemberNameClicked($event)"
          >
            Use Member Name To Sign In
          </aaa-button>
        </ng-template>
      </div>
    </ng-container>
  </form>
  <ion-row class="bottom-text">
    <ion-col>
      <aaa-text
        *ngIf="!(isSecure$ | async)"
        class="minimal-credentials_not-a-member color-black "
        display="block"
        align="center"
        role="navigation"
        aria-labelledby="login-help"
        color="primary"
        size="caption"
        weight="light"
      >
        <ng-container *ngIf="!allowNameAuth">
          <aria-link-button
            appCtaClickEvent="Forgot Member Number"
            i18n
            class="link-forgot-member-number"
            (clickLink)="openCannotRemember($event)"
            elementId="forgot-member-number"
            role="button"
          >
            Forgot Member Number
          </aria-link-button>
          |
        </ng-container>
        <aria-link-button
          appCtaClickEvent="Not A Member"
          i18n
          (clickLink)="openNotMember($event)"
          class="minimal-credentials_not-a-member-dialog"
          elementId="not-a-member"
          role="button"
        >
          Not a Member?
        </aria-link-button>
      </aaa-text>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="!configService.getConfig().nativeAppView" class="ion-justify-content-center">
    <ion-col class="ion-margin-bottom ion-text-center terms-section">
      <aaa-text
        i18n
        class="ion-margin-bottom"
        align="center"
        color="dark"
        size="caption"
        weight="regular"
        display="inline"
      >
        By selecting Confirm, you accept the
      </aaa-text>
      <aaa-text
        color="danger"
        align="center"
        size="caption"
        weight="regular"
        display="inline"
        role="navigation"
        aria-labelledby="terms"
      >
        <aria-link-button
          i18n
          elementId="terms"
          (clickLink)="openTerms()"
          class="terms-and-conditions__link"
        >
          Terms and Conditions
        </aria-link-button>
      </aaa-text>
      <ng-container *ngIf="!(isSecure$ | async) && isWebApp()">
        <aaa-text
          i18n
          color="dark"
          size="caption"
          weight="light"
          display="inline"
        > and </aaa-text>
        <aaa-text
          class="privacy-policy"
          id="privacy-policy"
          color="danger"
          size="caption"
          weight="regular"
          display="inline"
          aria-labelledby="privacy-policy"
          role="navigation"
        >
          <aria-link-button
            i18n
            (clickLink)="openPrivacyPolicy()"
            elementId="privacy-policy-link"
          >
            Privacy Policy
          </aria-link-button>
        </aaa-text>
      </ng-container>
      <ng-container *ngIf="captchaVersion === 'V3'">
        <drr-captcha-terms></drr-captcha-terms>
      </ng-container>
    </ion-col>
  </ion-row>
  <drr-captcha-modal [isOpen]="(captchaModalOpen$ | async)" (onDismiss)="handleCaptchaModalDismiss()">
    <div captcha>
      <ng-container *ngTemplateOutlet="captchaV2; context: { $implicit: { triggerAuth: true } }"></ng-container>
    </div>
  </drr-captcha-modal>
</section>
<ng-template #captchaV2 let-params>
  <ng-container *ngIf="displayCaptcha && captchaVersion === 'V2'">
    <re-captcha
      #captchaElement
      [siteKey]="siteKey"
      (resolved)="handleCaptchaSuccess($event, params.triggerAuth)"
    ></re-captcha>
  </ng-container>
</ng-template>
